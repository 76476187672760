import { AssetUrls } from '@app/api/resources/AppConfig';
import { FilmId } from '@app/api/resources/Film';
import { Promo, PromoPromoted } from '@app/api/resources/Promo';

import { ObjectOfStrings } from '@app/types/utility-types';

import * as actionTypes from '@app/actionTypes';
import {
  OnboardingConfig,
  OnboardingContext,
} from '@app/reducers/AppStateReducer';

export const setShowFindUsersModal = (showFindUsersModal: boolean) => ({
  type: actionTypes.SET_SHOW_FIND_USERS_MODAL,
  payload: {
    showFindUsersModal,
  },
});

export const setShowOnboardingModal = (showOnboardingModal: boolean) => ({
  type: actionTypes.SET_SHOW_ONBOARDING_MODAL,
  payload: {
    showOnboardingModal,
  },
});

export const setOnboardingContext = (onboardingContext: OnboardingContext) => ({
  type: actionTypes.SET_ONBOARDING_CONTEXT,
  payload: {
    onboardingContext,
  },
});

export const setOnboardingConfig = (onboardingConfig: OnboardingConfig) => ({
  type: actionTypes.SET_ONBOARDING_CONFIG,
  payload: {
    onboardingConfig,
  },
});

export const setShowManageFilmListModal = (
  showManageFilmListModal: boolean,
  filmId = null as FilmId,
  snowplowElement = null as string,
) => ({
  type: actionTypes.SET_SHOW_MANAGE_FILM_LIST_MODAL,
  payload: {
    showManageFilmListModal,
    filmId,
    snowplowElement,
  },
});

export const setShowRatingModal = (
  showRatingModal: boolean,
  filmId = null as FilmId,
  snowplowElement = null as string,
) => ({
  type: actionTypes.SET_SHOW_RATING_MODAL,
  payload: {
    showRatingModal,
    filmId,
    snowplowElement,
  },
});

export const setDetectedBrowser = (
  detectedBrowser: string,
  detectedBrowserVersion = null as string,
  detectedOperatingSystem = null as string,
) => ({
  type: actionTypes.SET_DETECTED_BROWSER,
  payload: {
    detectedBrowser,
    detectedBrowserVersion,
    detectedOperatingSystem,
  },
});

export const setIsMobile = (isMobile: boolean) => ({
  type: actionTypes.SET_IS_MOBILE,
  payload: {
    isMobile,
  },
});

export const setHttpContext = (httpContext: ObjectOfStrings) => ({
  type: actionTypes.SET_HTTP_CONTEXT,
  payload: {
    httpContext,
  },
});

export const addParamToHttpContext = (httpContextParam: ObjectOfStrings) => ({
  type: actionTypes.ADD_PARAM_TO_HTTP_CONTEXT,
  payload: {
    httpContextParam,
  },
});

export const setHideTopNav = () => ({
  type: actionTypes.SET_HIDE_TOP_NAV,
});

export const setUserHasInteractedWithPage = () => ({
  type: actionTypes.SET_USER_HAS_INTERACTED_WITH_PAGE,
});

export const setSnowplowInitialised = () => ({
  type: actionTypes.SET_SNOWPLOW_INITIALISED,
});

export const setPromoBannerCookies = (promoBannerCookies: {
  promoBannerFirstDismissDateTime: string;
  promoBannerSecondDismissDateTime: string;
}) => ({
  type: actionTypes.SET_PROMO_BANNER_COOKIES,
  payload: {
    promoBannerCookies,
  },
});

export const setPromoBannerPromo = (promoBannerPromo: Promo) => ({
  type: actionTypes.SET_PROMO_BANNER_PROMO,
  payload: {
    promoBannerPromo,
  },
});

export const setPromoPromoted = (promoPromoted: PromoPromoted) => ({
  type: actionTypes.SET_PROMO_PROMOTED,
  payload: {
    promoPromoted,
  },
});

export const setViewingTrackingIntervalSeconds = (
  viewingTrackingIntervalSeconds: number,
) => ({
  type: actionTypes.SET_VIEWING_TRACKING_INTERVAL,
  payload: {
    viewingTrackingIntervalSeconds,
  },
});

export const setSplashVideo = (videos: AssetUrls) => ({
  type: actionTypes.SET_SPLASH_VIDEO,
  payload: {
    videos,
  },
});

export const setSplashStill = (stills: AssetUrls) => ({
  type: actionTypes.SET_SPLASH_STILL,
  payload: {
    stills,
  },
});

export const setFilterBrowseByNowShowing = (
  shouldFilterBrowseByNowShowing: boolean,
) => ({
  type: actionTypes.SET_FILTER_BROWSE_FILMS_BY_NOW_SHOWING,
  payload: {
    shouldFilterBrowseByNowShowing,
  },
});

export const setContentWarningPreferenceEnabled = (
  contentWarningPreferenceEnabled: boolean,
) => ({
  type: actionTypes.SET_CONTENT_WARNING_PREFERENCE_ENABLED,
  payload: {
    contentWarningPreferenceEnabled,
  },
});

export const disableTrials = () => ({
  type: actionTypes.DISABLE_TRIALS,
});

export const showFlags = (showFlags: boolean) => ({
  type: actionTypes.SHOW_FLAGS,
  payload: {
    showFlags,
  },
});

export const setGoogleTagsInit = () => ({
  type: actionTypes.SET_GOOGLE_TAGS_INIT,
});

export const setVanityPathPageType = (vanityPathPageType: string) => ({
  type: actionTypes.SET_VANITY_PATH_PAGE_TYPE,
  payload: {
    vanityPathPageType,
  },
});

export const setSubscriptionCancellationStepIndex = (
  cancellationStepIndex: number,
) => ({
  type: actionTypes.SET_CANCELLATION_STEP,
  payload: {
    cancellationStepIndex,
  },
});

export const setMubiGoEnabledCountry = (mubiGoEnabledCountry: boolean) => ({
  type: actionTypes.SET_MUBI_GO_ENABLED,
  payload: {
    mubiGoEnabledCountry,
  },
});
