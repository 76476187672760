import isBot from 'isbot';
import { parseCookies, setCookie } from 'nookies';
import * as Sentry from '@sentry/nextjs';
import type { ErrorEvent, EventHint } from '@sentry/types';

import { CurrentUser } from '@app/api/resources/User';

import { isBrowserSupported } from '@app/services/device';

import { RootState } from '@app/reducers';

const COOKIE_ERROR_COUNT = 'error_count';
const MAX_ERRORS = 50;

const shouldSendErrorToSentry = (
  sendErrorProbability: number,
  errorEvent: ErrorEvent,
) => {
  const randomNumber = Math.random();
  if (randomNumber > sendErrorProbability) {
    return null;
  }
  return errorEvent;
};

const shouldIgnoreCedexisError = (errorEvent: ErrorEvent): boolean => {
  const exceptions = errorEvent?.exception?.values || [];

  const matchesCedexisError = exceptions.some(exception => {
    const url = (exception?.value as any)?.__sentry_xhr_v3__?.url;
    return url && /\.init\.cedexis-radar\.net/.test(url);
  });

  return matchesCedexisError;
};

export const getSentryConfig = () => {
  const sentryOptions = {
    environment: process.env.MUBI_ENV,
    dsn: process.env.SENTRY_DSN_FE,
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    beforeSend: null,
    tracesSampleRate: null,
    ignoreErrors: [
      'ResizeObserver loop completed with undelivered notifications.',
    ],
    denyUrls: [
      /https:\/\/www\.gstatic\.com\/recaptcha\/releases\/[\S]+\/[\S]+\.js/,
    ],
  };

  const hasExceededErrorSendLimit = () => {
    const errorCountCookie = parseCookies()[COOKIE_ERROR_COUNT];
    return errorCountCookie && parseInt(errorCountCookie) >= MAX_ERRORS;
  };

  const incrementErrorCount = () => {
    const errorCountCookie = parseCookies()[COOKIE_ERROR_COUNT];

    if (errorCountCookie) {
      setCookie(null, COOKIE_ERROR_COUNT, (+errorCountCookie + 1).toString(), {
        path: '/',
      });
    } else {
      setCookie(null, COOKIE_ERROR_COUNT, '1', {
        path: '/',
      });
    }
  };

  // When we're developing locally
  if (
    process.env.MUBI_ENV === 'development' ||
    process.env.MUBI_ENV === 'test'
  ) {
    // Don't actually send the errors to Sentry
    sentryOptions.beforeSend = () => null;
  } else {
    sentryOptions.beforeSend = (event: ErrorEvent, hint: EventHint) => {
      if ([7289876, 8846454].includes(event?.user?.user_id)) {
        return event;
      }

      try {
        if (hasExceededErrorSendLimit()) {
          return null;
        }
        incrementErrorCount();
      } catch {
        return null;
      }

      if (isBot(event?.request?.headers?.['user-agent'])) {
        return null;
      }

      if (
        typeof event?.message === 'string' &&
        event?.message?.includes("can't access dead object")
      ) {
        return null;
      }

      if (hint.originalException === 'Timeout') {
        return null;
      }

      if (shouldIgnoreCedexisError(event)) {
        return null;
      }

      if (event?.exception?.values?.[0]) {
        const errorValues = event?.exception?.values?.[0];

        if (errorValues?.mechanism?.handled) {
          return shouldSendErrorToSentry(0.2, event);
        }

        if (errorValues?.type === 'ChunkLoadError') {
          return shouldSendErrorToSentry(0.1, event);
        }

        if (errorValues?.value === 'Network Error') {
          return shouldSendErrorToSentry(0.1, event);
        }
      }
      return event;
    };
  }

  if (process.env.MUBI_ENV === 'production') {
    // Turn off tracing as we don't use it and it bloats the bundle size by 100kb
    sentryOptions.tracesSampleRate = 0;
  }

  return sentryOptions;
};

export const identifyUserInSentry = async (
  user: CurrentUser,
  snowplowDuid: string,
) => {
  const userContext = {
    id: null,
    user_id: null,
    email: null,
    active_subscriber: null,
  };

  if (snowplowDuid) {
    userContext.id = snowplowDuid;
  }

  if (user && user?.email) {
    userContext.user_id = user.id;
    userContext.email = user.email;
    userContext.active_subscriber = user.active_subscriber;
  }

  await Sentry.setUser(userContext);
};

export const sentryIgnoreErrorsFromUnsupportedBrowsers = (
  currentState: RootState,
) => {
  const browserName = currentState?.appState?.detectedBrowser;
  const browserVersion = currentState?.appState?.detectedBrowserVersion;

  const shouldSendErrorToSentry = isBrowserSupported({
    name: browserName,
    version: browserVersion,
  });

  Sentry.addEventProcessor(event => {
    if (shouldSendErrorToSentry === false) {
      return null;
    }
    return event;
  });
};
