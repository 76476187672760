import Cookies from 'js-cookie';
import cloneDeep from 'lodash/cloneDeep';
import { setCookie } from 'nookies';

import { Country } from '@app/api/resources/Countries';
import { Film, FilmId, FilmSlug } from '@app/api/resources/Film';
import { FilmGroup, FilmGroupId } from '@app/api/resources/FilmGroup';
import { NotebookPost } from '@app/api/resources/notebook/NotebookPost';
import { TodayPageModuleType } from '@app/api/resources/TodayLayout';
import { UserId } from '@app/api/resources/User';

import { SearchPageModuleType } from '@app/api/services/search';
import { VanityPathPageType } from '@app/api/services/vanity-paths';
import { OnboardingFlowAnalytics } from '@app/onboarding/services/onboarding-types';
import { TWO_YEARS_IN_DAYS } from '@app/services/date-utils';
import {
  ArchetypeKey,
  getRetrospectivePageStructure,
} from '@app/services/retrospective-24';
import {
  checkPathnameMatches,
  checkPathnamesAreEqual,
  checkPathnameStartsWith,
  stripVanityPrefix,
} from '@app/services/routeHelpers';
import { isClient } from '@app/services/utils';

import { ObjectOfAny, ObjectOfStrings } from '@app/types/utility-types';

import { RetrospectiveState } from '@app/reducers/RetrospectiveReducer';

import { checkStartupSession } from '@app/services/initialisation';

declare let window: {
  snowplow: any;
  innerWidth: any;
};

const isProd = process.env.MUBI_ENV === 'production';

const snowplowMubiDomain = '.mubi.com';

export const updateSnowplowDuid = newSnowplowDuid => {
  if (newSnowplowDuid && isClient()) {
    const matcher = new RegExp('(_sp_id\\.[a-f0-9]+)=([^;]+);?');
    const match = document.cookie.match(matcher);

    if (match && match?.[1]?.length > 0 && match?.[2]?.length > 0) {
      const cookieName = match[1];
      const currentSnowplowCookieValueWithoutDuid = match[2]?.substring(
        match[2].indexOf('.'),
      );

      const newSnowplowCookieValue = `${newSnowplowDuid}${currentSnowplowCookieValueWithoutDuid}`;

      setCookie(null, cookieName, newSnowplowCookieValue, {
        maxAge: TWO_YEARS_IN_DAYS,
        domain: snowplowMubiDomain,
        path: '/',
      });
    }
  }
};

export const setCustomSnowplowPageUrl = (pageUrl: string) => {
  window.snowplow('setCustomUrl', pageUrl);
};

export const updateSnowplowDuidIfNecessary = () => {
  if (shouldTrackEvents()) {
    // Calling a function wrapped in snowplow() will execute it as soon as sp.js has initialised
    window.snowplow(() => {
      const snowplowDuid = Cookies.get('snowplowDuid');
      if (snowplowDuid) {
        updateSnowplowDuid(snowplowDuid);
        Cookies.remove('snowplowDuid');
      }
    });
  }
};

export const getSnowplowDuid = (req: any = {}) => {
  const matcher = new RegExp('_sp_id\\.[a-f0-9]+=([^;]+);?');

  let match;
  if (isClient()) {
    match = document.cookie.match(matcher);
  } else {
    match = req?.headers?.cookie?.match(matcher);
  }

  if (match && match[1]) {
    return match[1].split('.')[0];
  }
  return '';
};

const getSchema = (namespace: string, event_name: string) =>
  `iglu:com.mubi/${namespace}_${event_name}/jsonschema/1-0-0`;

const shouldTrackEvents = () => isClient() && isProd && window.snowplow;

export const setSnowplowUser = (userId: UserId) => {
  if (shouldTrackEvents()) {
    if (userId) {
      window.snowplow('setUserId', userId);
    }
  }
};

export const initSnowplow = (userId: UserId) => {
  if (shouldTrackEvents()) {
    const snowplowConfig = {
      appId: 'mubi-frontend',
      cookieDomain: snowplowMubiDomain,
      forceSecureTracker: true,
      bufferSize: 8,
    } as ObjectOfAny;

    window.snowplow('newTracker', 'cf', 'st.mubi.com', snowplowConfig);

    document.addEventListener('beforeunload', () => {
      clearInterval(intervalId);

      if (window.snowplow) {
        try {
          window.snowplow('flushBuffer');
        } catch (error) {
          //
        }
      }
    });

    const intervalId = setInterval(() => {
      if (window.snowplow) {
        try {
          window.snowplow('flushBuffer');
        } catch (error) {
          //
        }
      }
    }, 2000);

    setSnowplowUser(userId);
  }
};

const trackSnowplowEvent = (
  namespace: string,
  event_name: string,
  passedData: ObjectOfAny,
  callback?: () => void,
  httpContext?: ObjectOfStrings,
) => {
  if (shouldTrackEvents()) {
    const data = cloneDeep(passedData);
    data.user_timestamp = new Date().toISOString();
    if (namespace !== 'test') {
      data.platform = 'web';
      data.screen_width = window.innerWidth;
    }
    window.snowplow(
      'trackSelfDescribingEvent',
      {
        schema: getSchema(namespace, event_name),
        data,
      },
      null,
      null,
      () => {
        if (callback) {
          callback();
        }
      },
    );
    checkStartupSession(httpContext);
  } else {
    if (callback) {
      callback();
    }
    if (!isProd) {
      console.log(`Snowplow ${event_name}`, passedData);
    }
  }
};

export const trackSnowplowQuickSearchEvent = (
  eventData: ObjectOfAny,
  httpContext: ObjectOfStrings,
) => {
  trackSnowplowEvent('frontend', 'quick_search', eventData, null, httpContext);
};

export const trackSnowplowQuickSearchSuggestionsEvent = (
  eventData: ObjectOfAny,
  httpContext: ObjectOfStrings,
) => {
  trackSnowplowEvent(
    'frontend',
    'search_suggestion_shown',
    eventData,
    null,
    httpContext,
  );
};

export const trackSnowplowScrollEvent = (
  eventData: ObjectOfAny,
  httpContext: ObjectOfStrings,
) => {
  trackSnowplowEvent('frontend', 'scroll', eventData, null, httpContext);
};

export const trackSnowplowClickEvent = (
  eventData: ObjectOfAny,
  callback: () => void,
  httpContext: ObjectOfStrings,
) => {
  trackSnowplowEvent('frontend', 'click', eventData, callback, httpContext);
};

type SnowplowContentCardEvent = {
  interaction_type: string;
  card_id: string;
  country_code: string;
  page_type: string;
  sub_page?: string;
};

export const trackSnowplowContentCardEvent = (
  eventData: SnowplowContentCardEvent,
  callback: () => void,
  httpContext: ObjectOfStrings,
) => {
  trackSnowplowEvent(
    'frontend',
    'content_cards',
    eventData,
    callback,
    httpContext,
  );
};

export const trackSnowplowFilmImpression = (
  eventData: ObjectOfAny,
  callback: () => void,
  httpContext: ObjectOfStrings,
) => {
  trackSnowplowEvent('film', 'impression', eventData, callback, httpContext);
};

export const trackSnowplowTestEvent = (
  eventData: ObjectOfAny,
  httpContext: ObjectOfStrings,
) => {
  trackSnowplowEvent('test', 'event', eventData, null, httpContext);
};

export const trackSnowplowPageView = (
  pageData: ObjectOfAny,
  httpContext: ObjectOfStrings,
) => {
  trackSnowplowEvent('frontend', 'page_view', pageData, null, httpContext);
};

export const trackSnowplowPageRead = (
  eventData: ObjectOfAny,
  httpContext: ObjectOfStrings,
) => {
  trackSnowplowEvent('frontend', 'page_read', eventData, null, httpContext);
};

export const trackSnowplowTilePreview = (
  eventData: ObjectOfAny,
  httpContext: ObjectOfStrings,
) => {
  trackSnowplowEvent('frontend', 'tile_preview', eventData, null, httpContext);
};

export const trackSnowplowModalView = (
  eventData: ObjectOfAny,
  callback: () => void,
  httpContext: ObjectOfStrings,
) => {
  trackSnowplowEvent(
    'frontend',
    'modal_view',
    eventData,
    callback,
    httpContext,
  );
};

export const trackSnowplowBannerView = (
  eventData: ObjectOfAny,
  httpContext: ObjectOfStrings,
) => {
  trackSnowplowEvent('frontend', 'banner_shown', eventData, null, httpContext);
};

export const trackSnowplowOnboarding = (
  eventData: ObjectOfAny,
  httpContext: ObjectOfStrings,
) => {
  trackSnowplowEvent('frontend', 'onboarding', eventData, null, httpContext);
};

export type FilmImpression = {
  filmId: number;
  isWatchable?: boolean;
  collectionSlug?: string;
  filmGroupId?: FilmGroupId;
  sectionNumber?: number; // Remove once we remove showing page
  indexInSection?: number;
  moduleType?: string;
  horizontalPosition?: number;
  verticalPosition?: number;
  searchRank?: number;
};

export type PageTypes =
  | 'film'
  | 'film_group'
  | 'notebook'
  | 'showing'
  | 'film_of_the_day'
  | 'cast'
  | 'list'
  | 'promo'
  | 'splash'
  | 'watch'
  | 'go'
  | 'trailer'
  | 'watchlist'
  | 'student'
  | 'profile'
  | 'search_films'
  | 'search_cast'
  | 'search_notebook'
  | 'search_lists'
  | 'watch'
  | 'splash_film'
  | 'browse'
  | 'subscription_cancellation'
  | 'about'
  | 'awards_and_festivals'
  | 'gifts'
  | 'viewing_history'
  | 'referral'
  | 'special_promo'
  | 'release'
  | 'student_splash_partner'
  | 'microsite'
  | `retrospective_${string}_generic`
  | `retrospective_${string}_personal`
  | `retrospective_${string}_impersonal`;

export type SubPageTypes =
  | 'posts'
  | 'author'
  | 'category'
  | 'contributor'
  | 'issue_0_preorder'
  | 'cast_credit_type'
  | 'before_you_go'
  | 'before_you_go_with_option_to_break'
  | 'extended_trial'
  | 'offer_discount'
  | 'offer_grandfather'
  | 'cancel_reason'
  | 'awards-and-festivals'
  | 'films'
  | 'lists'
  | 'cast'
  | `onboarding_${OnboardingFlowAnalytics}`
  | ArchetypeKey;

export type PageModuleType =
  | TodayPageModuleType
  | SearchPageModuleType
  | 'subscription_cancellation';

export type FilmTrackingDetails = {
  sectionNumber?: number;
  moduleType?: PageModuleType;
  horizontalPosition?: number;
  isWatchable?: boolean;
  verticalPosition?: number;
  indexInSection?: number;
  collectionSlug?: string;
  filmGroupId?: FilmGroupId;
  searchRank?: number;
};

export type ObjectOfFilmTrackingDetails = {
  [key: FilmId]: FilmTrackingDetails;
};

export type SnowplowPage = {
  page_type: PageTypes;
  sub_page?: SubPageTypes;
};

export type SnowplowBasicData = {
  page_type?: PageTypes;
  sub_page?: SubPageTypes;
  country_code?: Country['code'];
  element?: string;
  click_type?: string;
  collection_slug?: string;
};

export const getSnowplowPageType = async (
  {
    pathname,
    query,
  }: {
    pathname: string;
    query?: {
      searchType?: 'films' | 'cast' | 'notebook' | 'lists';
      filmId?: string;
      pause_option?: boolean;
    };
  },
  vanityPathPageType?: VanityPathPageType | 'splash',
  retrospectiveState?: RetrospectiveState,
): Promise<SnowplowPage> => {
  let pageType: { page_type: PageTypes; sub_page?: SubPageTypes } = null;

  if (pathname === '/[vanityPath]') {
    pageType = {
      page_type: vanityPathPageType,
    };
  } else if (checkPathnamesAreEqual(pathname, '/films/[filmSlug]')) {
    pageType = {
      page_type: 'film',
    };
  } else if (
    checkPathnamesAreEqual(pathname, '/notebook') ||
    checkPathnameMatches(pathname, /^\/notebook\/.*/)
  ) {
    pageType = {
      page_type: 'notebook',
    };
    if (checkPathnamesAreEqual(pathname, '/notebook/posts/[post_slug]')) {
      pageType.sub_page = 'posts';
    } else if (
      checkPathnamesAreEqual(pathname, '/notebook/posts/author/[author_id]')
    ) {
      pageType.sub_page = 'author';
    } else if (
      checkPathnamesAreEqual(pathname, '/notebook/posts/tag/[tag_slug]')
    ) {
      pageType.sub_page = 'category';
    } else if (checkPathnamesAreEqual(pathname, '/notebook/contributors')) {
      pageType.sub_page = 'contributor';
    } else if (checkPathnamesAreEqual(pathname, '/notebook/magazine')) {
      pageType.sub_page = 'issue_0_preorder';
    }
  } else if (checkPathnamesAreEqual(pathname, '/showing')) {
    pageType = {
      page_type: 'showing',
    };
  } else if (
    checkPathnamesAreEqual(pathname, '/collections/[collectionSlug]')
  ) {
    pageType = {
      page_type: 'film_group',
    };
  } else if (checkPathnamesAreEqual(pathname, '/film-of-the-day')) {
    pageType = {
      page_type: 'film_of_the_day',
    };
  } else if (checkPathnameMatches(pathname, /^\/cast\/\[castSlug\].*/)) {
    pageType = {
      page_type: 'cast',
    };
    if (
      checkPathnamesAreEqual(pathname, '/cast/[castSlug]/films/[creditSlug]')
    ) {
      pageType.sub_page = 'cast_credit_type';
    }
  } else if (
    checkPathnamesAreEqual(pathname, '/lists/[...params]') ||
    checkPathnamesAreEqual(pathname, '/lists/[listSlug]')
  ) {
    pageType = {
      page_type: 'list',
    };
  } else if (checkPathnamesAreEqual(pathname, '/promos/[promoVanityPath]')) {
    pageType = {
      page_type: 'promo',
    };
  } else if (checkPathnamesAreEqual(pathname, '/')) {
    pageType = {
      page_type: 'splash',
    };
  } else if (checkPathnamesAreEqual(pathname, '/student')) {
    pageType = {
      page_type: 'student',
    };
  } else if (checkPathnamesAreEqual(pathname, '/users/[...userIdAndSubPage]')) {
    pageType = {
      page_type: 'profile',
    };
  } else if (checkPathnamesAreEqual(pathname, '/search/[searchType]')) {
    pageType = {
      page_type: `search_${query?.searchType}`,
    };
  } else if (checkPathnamesAreEqual(pathname, '/films/[filmSlug]/player')) {
    pageType = {
      page_type: 'watch',
    };
  } else if (checkPathnameStartsWith(pathname, '/go')) {
    pageType = {
      page_type: 'go',
    };
  } else if (checkPathnamesAreEqual(pathname, '/films/[filmSlug]/trailer')) {
    pageType = {
      page_type: 'trailer',
    };
  } else if (checkPathnamesAreEqual(pathname, '/watchlist')) {
    pageType = {
      page_type: 'watchlist',
    };
  } else if (checkPathnamesAreEqual(pathname, '/join')) {
    if (query.filmId) {
      pageType = {
        page_type: 'splash_film',
      };
    }
  } else if (
    checkPathnamesAreEqual(pathname, '/subscription/before_you_go') ||
    checkPathnameStartsWith(pathname, '/subscription/offer_') ||
    checkPathnamesAreEqual(pathname, '/subscription/cancel')
  ) {
    pageType = {
      page_type: 'subscription_cancellation',
    };
    if (checkPathnamesAreEqual(pathname, '/subscription/before_you_go')) {
      pageType.sub_page = 'before_you_go';
      if (query?.pause_option) {
        pageType.sub_page = 'before_you_go_with_option_to_break';
      }
    } else if (
      checkPathnamesAreEqual(pathname, '/subscription/offer_extended_trial')
    ) {
      pageType.sub_page = 'extended_trial';
    } else if (
      checkPathnamesAreEqual(pathname, '/subscription/offer_discount')
    ) {
      pageType.sub_page = 'offer_discount';
    } else if (
      checkPathnamesAreEqual(pathname, '/subscription/offer_grandfather')
    ) {
      pageType.sub_page = 'offer_grandfather';
    } else if (checkPathnamesAreEqual(pathname, '/subscription/cancel')) {
      pageType.sub_page = 'cancel_reason';
    }
  } else if (
    checkPathnamesAreEqual(pathname, '/awards-and-festivals') ||
    checkPathnamesAreEqual(pathname, '/films') ||
    checkPathnamesAreEqual(pathname, '/lists') ||
    checkPathnamesAreEqual(pathname, '/cast')
  ) {
    pageType = {
      page_type: 'browse',
      sub_page: pathname.substring(1) as SubPageTypes,
    };
  } else if (
    checkPathnamesAreEqual(
      pathname,
      '/awards-and-festivals/[industryEventSlug]',
    )
  ) {
    pageType = { page_type: 'awards_and_festivals' };
  } else if (checkPathnamesAreEqual(pathname, '/viewing-history')) {
    pageType = { page_type: 'viewing_history' };
  } else if (
    checkPathnamesAreEqual(
      pathname,
      '/t/[referralPlatform]/[referralSource]/[userCode]',
    )
  ) {
    pageType = { page_type: 'referral' };
  } else if (
    checkPathnamesAreEqual(pathname, '/thesubstance') ||
    checkPathnamesAreEqual(pathname, '/lasustancia')
  ) {
    pageType = { page_type: 'microsite' };
  } else if (
    checkPathnamesAreEqual(pathname, '/retrospective/[tokenOrCountryCode]')
  ) {
    if (retrospectiveState) {
      const { retrospective, referralPageContent } = retrospectiveState;
      const { activePage, variant, activeArchetype } =
        retrospectiveState.trackingInfo;
      pageType = {
        page_type:
          activePage === 'start_page'
            ? `retrospective_get_started_${variant}`
            : `retrospective_${
                getRetrospectivePageStructure(
                  retrospective,
                  referralPageContent?.hidePage,
                ).find(page => page.pageIndex === activePage).label
              }_${variant}`,
      };
      if (activeArchetype) {
        pageType.sub_page = activeArchetype;
      }
    }
  } else {
    pageType = {
      page_type: stripVanityPrefix(pathname),
    };
  }
  return pageType;
};

export const getSnowplowRelatedIdForPageType = (
  pageUrl: string,
  routerParams: {
    filmSlug?: FilmSlug;
    collectionSlug?: string;
    post_slug?: string;
    author_id?: string;
    filmId?: string;
  },
  pageType: PageTypes,
  relatedData: {
    films?: {
      [key: FilmId]: Film;
    };
    filmGroups?: {
      [key: FilmGroupId]: FilmGroup;
    };
    notebookPosts?: {
      [key: number]: NotebookPost;
    };
  },
) => {
  if (pageType === 'film' && routerParams?.filmSlug) {
    const { films } = relatedData;
    const relatedFilm = Object.values(films)?.find(
      film => film.slug === routerParams.filmSlug,
    );
    return relatedFilm?.id
      ? {
          film_id: relatedFilm.id,
        }
      : null;
  }
  if (pageType === 'film_group' && routerParams?.collectionSlug) {
    return {
      collection_slug: routerParams?.collectionSlug,
    };
  }
  if (pageType === 'notebook' && routerParams?.post_slug) {
    const { notebookPosts } = relatedData;
    const relatedNotebookPost = Object.values(notebookPosts)?.find(
      notebookPost => notebookPost.slug === routerParams.post_slug,
    );
    if (relatedNotebookPost?.id) {
      const relatedNotebookPostData: {
        notebook_id: number;
        notebook_publish_dt: string;
        author_id?: number;
      } = {
        notebook_id: relatedNotebookPost.id,
        notebook_publish_dt: relatedNotebookPost.published_at,
      };

      if (relatedNotebookPost.authors.length > 0) {
        relatedNotebookPostData.author_id = relatedNotebookPost.authors[0].id;
      }

      return relatedNotebookPostData;
    }
  }
  if (pageType === 'notebook' && routerParams?.author_id) {
    return {
      author_id: parseInt(routerParams.author_id, 10),
    };
  }
  if (pageType === 'splash_film' && routerParams?.filmId) {
    const { films } = relatedData;
    const relatedFilm = Object.values(films)?.find(
      film => film.id === parseInt(routerParams.filmId, 10),
    );
    return relatedFilm?.id
      ? {
          film_id: relatedFilm.id,
        }
      : null;
  }

  if (
    pageType === 'microsite' &&
    (pageUrl === '/thesubstance' || pageUrl === '/lasustancia')
  ) {
    return {
      film_id: 332868,
    };
  }

  return null;
};

export const getCollectionSlugIfCollectionPage = (
  snowplowPage: SnowplowPage,
  collectionSlug?: string,
) => {
  if (snowplowPage.page_type === 'film_group' && collectionSlug) {
    return {
      collection_slug: collectionSlug,
    };
  }
};
