import { AnyAction } from 'redux';
import produce from 'immer';

import {
  ArchetypeKey,
  RetroReferralPage,
  Retrospective,
  RetrospectiveVariant,
} from '@app/services/retrospective-24';

import * as actionTypes from '@app/actionTypes';

export type RetrospectiveState = {
  retrospective: Retrospective;
  forceIsPlaying: boolean;
  trackingInfo: {
    activePage: number | 'start_page';
    variant: RetrospectiveVariant;
    activeArchetype: ArchetypeKey;
  };
  referralPageContent: RetroReferralPage;
  navigationOverride: {
    handleNextOverride: () => void;
    handlePreviousOverride: () => void;
  };
};

export const initialState: RetrospectiveState = {
  retrospective: {
    archetypes: null,
    anti_archetypes: null,
    assets: null,
  },
  forceIsPlaying: null,
  trackingInfo: { activePage: null, variant: null, activeArchetype: null },
  referralPageContent: {
    hidePage: false,
    textKey: '',
    subtextKey: '',
    ctaAndAction: {
      ctaKey: '',
      clickAction: null,
      snowplowClickType: '',
    },
    showAppCopyLink: false,
  },
  navigationOverride: {
    handleNextOverride: () => {},
    handlePreviousOverride: () => {},
  },
};

const retrospective = (
  state: RetrospectiveState = initialState,
  action: AnyAction,
) =>
  produce(state, draft => {
    switch (action.type) {
      case actionTypes.SET_RETROSPECTIVE:
        draft.retrospective = action.payload.retrospective;
        break;
      case actionTypes.SET_FORCE_IS_PLAYING:
        draft.forceIsPlaying = action.payload.forceIsPlaying;
        break;
      case actionTypes.SET_ACTIVE_RETROSPECTIVE_PAGE_INDEX:
        draft.trackingInfo.activePage = action.payload.activePage;
        break;
      case actionTypes.SET_RETROSPECTIVE_VARIANT:
        draft.trackingInfo.variant = action.payload.variant;
        break;
      case actionTypes.SET_RETROSPECTIVE_ACTIVE_ARCHETYPE:
        draft.trackingInfo.activeArchetype = action.payload.activeArchetype;
        break;
      case actionTypes.SET_REFERRAL_PAGE_CONTENT:
        draft.referralPageContent = action.payload.referralPageContent;
        break;
      case actionTypes.SET_HANDLE_NEXT_OVERRIDE:
        draft.navigationOverride.handleNextOverride =
          action.payload.handleNextOverride;
        break;
      case actionTypes.SET_HANDLE_PREVIOUS_OVERRIDE:
        draft.navigationOverride.handlePreviousOverride =
          action.payload.handlePreviousOverride;
        break;
    }
  });

export default retrospective;
