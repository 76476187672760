import HttpService from '@app/api/http-service';
import { ObjectOfStrings } from '@app/api/utility-types';

import { isEmpty } from '@app/services/utils';

export type SubscriptionPlanName =
  | 'MUBI Monthly'
  | 'MUBI Yearly'
  | 'MUBI Monthly (Trial)'
  | 'MUBI Yearly (Trial)'
  | 'MUBI GO Monthly'
  | 'MUBI GO Yearly'
  | 'MUBI GO Monthly (Trial)'
  | 'MUBI GO Yearly (Trial)';

export type SubscriptionPlanKeys =
  | 'month'
  | 'year'
  | 'month_premium'
  | 'year_premium'
  | 'month_student';

export type SubscriptionPlanBase = {
  name: SubscriptionPlanName;
  display_name?: string;
  price: string;
  effective_monthly_price?: string;
  savings_percent?: string;
  renewal_period: number | string;
  days?: number;
  trial_days?: number;
  selection_key?: SubscriptionPlanKeys;
  includes_mubi_go?: boolean;
};

type IndividualPlan = {
  sku: string;
  payment_platform: string;
  premium: boolean;
  prompt_upgrade: boolean;
  purchase_token: string;
};

export type SubscriptionStatus =
  | 'cancelled'
  | 'expired'
  | 'paid'
  | 'trial'
  | 'locked'
  | 'marked_for_cancellation'
  | 'failed'
  | 'marked_for_pause'
  | 'paused';

export type SubscriptionPlan = SubscriptionPlanBase & {
  id: number | string;
  status: SubscriptionStatus;
  expires_at: string;
  payment_platform:
    | 'mubi'
    | 'psn'
    | 'amazon'
    | 'itunes'
    | 'roku'
    | 'play'
    | 'digi';
  price_in_cents: number;
  price_currency: string;
  type: 'subscription' | 'day_pass';
  period: number;
  description: string;
  discount_savings_percent: string;
  external: boolean;
  plan: IndividualPlan;
  resubscribe_price?: string;
  eligible_for_trial_extension?: boolean;
  country_code: string;
};

export type SubscriptionPlans = {
  month?: SubscriptionPlanBase;
  year?: SubscriptionPlanBase;
  month_premium?: SubscriptionPlanBase;
  year_premium?: SubscriptionPlanBase;
  month_student?: SubscriptionPlanBase;
};

export const getSubscriptionPlans = (
  httpContext: ObjectOfStrings,
): Promise<{ data: SubscriptionPlanBase[] }> =>
  HttpService(httpContext).get('/subscription_plans');

export const getSubscriptionPlansForUser = (
  httpContext: ObjectOfStrings,
): Promise<{ data: SubscriptionPlanBase[] }> =>
  HttpService(httpContext).get('/subscription_plans/for_user');

export const getSubscriptionUpgradeCost = (
  httpContext: ObjectOfStrings,
): Promise<{ data: { fee: string } }> =>
  HttpService(httpContext).get('/subscription_plans/upgrade_path');

export const getSubscriptionPlansToOffer = (
  plansForAllInCountry: SubscriptionPlans,
  plansForUser: SubscriptionPlans,
) => {
  if (!isEmpty(plansForUser)) {
    return plansForUser;
  }
  return plansForAllInCountry;
};
