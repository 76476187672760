import { css } from '@emotion/react';

import { NestedKeyOf } from '@app/types/utility-types';

export const font = {
  brand: 'DMSans, Helvetica, Arial, "Lucida Grande", sans-serif',
  body: 'DMSans, Helvetica, Arial, "Lucida Grande", sans-serif',
  title: 'Riforma, DMSans, Helvetica, Arial, sans-serif',
  unantialias: css`
    -webkit-font-smoothing: auto;
    -moz-osx-font-smoothing: auto;
  `,
} as const;

export const color = {
  black: '#000000',
  white: '#FFFFFF',
  lightBackground: '#f6f6f6',
  beigeBackground: '#F3F1EA',
  midBackground: '#EAEAEA',
  lightGray: '#C8C8C8',
  midGray: '#9B9B9B',
  darkGray: '#7d7d7d',
  lightText: '#666666',
  darkText: '#323232',
  darkBackground: '#1F1F1F',
  mubiBlue: '#001489',
  mubiBlueHover: '#001AAF',
  criticBackground: '#FDF6F0',
  criticLoadingBg: '#EDE4DD',
  criticLoadingFg: '#D5C9BF',
  goYellow: '#FEFB61',
  yellow: '#FBE725',
  yellowOnboardingOffer: '#FEE300',
  lightBlue: '#c4e3f9',
  alertRed: '#B00000',
  errorRed: '#F51516',
  giftRed: '#ff2728',
  orange: '#FEA329',
  darkBlue: '#000CB2',
  blueBackground: '#3100B0',
  electricLime: '#9EF83A',
  free: '#C60100',
  favorite: '#F51315',
  java: '#23C7AE',
  goBeige: '#F3F1EB',
  goBeige2: '#F3F1EA',
  social: {
    facebook: '#3B5998',
    twitter: '#1DA1F2',
  },
  canary: '#DEFC52',
  notebookMagazine: {
    ctaButton: '#D71B1D',
    blackBanner: '#290A24',
  },
  go: {
    red: '#ff4238',
    pink: '#FFA49B',
    lightPink: '#FFB4AC',
    goldenYellow: '#FFC000',
    brown: '#2d2420',
    blue: '#4242FF',
    turquoise: '#86FFEE',
  },
  rgbatransparent: 'rgba(0,0,0,0)',
  rgbaBlack150pacity: 'rgba(0, 0, 0, 0.15)',
  rgbaBlack40Opacity: 'rgba(0, 0, 0, 0.4)',
  rgbaBlack60Opacity: 'rgba(0, 0, 0, 0.6)',
  rgbaWhite60Opacity: 'rgba(255, 255, 255, 0.6)',
  rating: {
    adult: '#DD2323',
    caution: '#E05D04',
    general: '#304BE9',
  },
  progressBar: {
    background: 'rgba(255, 255, 255, 0.34)',
  },
  subscription: {
    warning: '#fb7404',
  },
  retrospective: {
    electricMint: '#00FF9E',
    yellow: '#FFDD00',
    blue: '#2E00FF',
    purple: '#2D0D4C',
    lighterPurple: '#3A105B',
  },
} as const;

export type ColorKey = NestedKeyOf<typeof color>;

export type ColorHex = `#${string}`;

export const maxPageWidth = {
  mobile: '385px',
  tablet: '750px',
  desktop: '1130px',
  wide: '1510px',
  wideLarge: '1770px',
} as const;

export const maxPageWidthNew = {
  mobile: '375px',
  tablet: '792px',
  desktop: '1168px',
  wide: '1544px',
  wideLarge: '1920px',
} as const;

export const maxPageWidthNumbers = {
  mobile: 385,
  tablet: 750,
  desktop: 1130,
  wide: 1510,
  wideLarge: 1770,
} as const;

export const maxPageWidthNumbersNew = {
  mobile: 375,
  tablet: 792,
  desktop: 1168,
  wide: 1544,
  wideLarge: 1920,
} as const;

export const mq = {
  mobile: '388px',
  tablet: '768px',
  desktop: '1148px',
  wide: '1528px',
  wideLarge: '1788px',
} as const;

export const mqNew = {
  mobile: '380px',
  tablet: '810px',
  desktop: '1186px',
  wide: '1562px',
  wideLarge: '1938px',
} as const;

export const mqToday = {
  tabletLarge: '1024px',
  tabletLargeNumber: 1024,
  tabletSmall: '735px',
  tabletSmallNumber: 735,
} as const;

export type Breakpoints = keyof typeof mq;

export const mqNumbers = {
  mobile: 388,
  tablet: 768,
  desktop: 1148,
  wide: 1528,
  wideLarge: 1788,
  wideXLarge: 1920,
  wideXXLarge: 2560,
} as const;

export const responsiveImageBreakpoints = {
  mobile: '550px',
  tablet: '810px',
  desktop: '1400px',
  wide: '2200px',
} as const;

export const mqNewNumbers = {
  mobile: 380,
  tablet: 810,
  desktop: 1186,
  wide: 1562,
  wideLarge: 1938,
} as const;

export const helpers = {
  supportsHoverMq:
    '(hover: hover), all and (-ms-high-contrast: none), (-ms-high-contrast: active)',
  touchscreenMq: '(hover: none)',
  linkStyles: css`
    cursor: pointer;
    text-decoration: none;
    color: ${color.mubiBlue};

    &:hover {
      color: ${color.mubiBlue};
      text-decoration: underline;
    }
    &:visited {
      color: ${color.mubiBlue};
    }
  `,
  heroHeight: css`
    /* short screens: don't let the tile shrink smaller than it's standard height */
    /* tall screens: force the tile back to it's standard height, to reveal some of yesterday's FOTD */
    min-height: 506px;
    height: calc(100vh - 360px);
    @media (min-height: 1000px) {
      height: 506px;
    }

    @media (min-width: ${mq.tablet}) {
      min-height: 380px;
      height: calc(100vh - 316px);
    }
    @media (min-width: ${mq.tablet}) and (min-height: 900px) {
      max-height: 380px;
    }

    @media (min-width: ${mq.desktop}) {
      min-height: 450px;
      height: calc(100vh - 316px);
    }
    @media (min-width: ${mq.desktop}) and (min-height: 900px) {
      max-height: none;
    }
    @media (min-width: ${mq.desktop}) and (min-height: 1020px) {
      max-height: 450px;
    }

    @media (min-width: ${mq.wide}) {
      min-height: 645px;
      height: calc(100vh - 318px);
    }
    @media (min-width: ${mq.wide}) and (min-height: 900px) {
      max-height: none;
    }

    @media (min-width: ${mq.wide}) and (min-height: 1200px) {
      max-height: 645px;
    }
  `,
} as const;

const animations = {
  switchButton: 'cubic-bezier(0.25, 0.46, 0.45, 0.94)',
  filmTileDefault: 'linear',
};

export const grid = {
  standardFilmTileHeight: 209,
  mobileDoubleTileHeight: 432,
  standardDoubleTileHeight: 418,
  standardItemWidth: 372,
  standardItemExpandedWidth: 396,
  standardItemWidthMobile: 335,
  standardItemExpandedWidthMobile: 358,
  standardItemExpandedHeight: 222,
  standardGridGap: 4,
  gridPadding: 22,
  mobileGridPadding: 20,
  legacyMobileGridPadding: 15,
  gridMarginBottom: 36,
} as const;

const BreakpointOrder = {
  mobile: 1,
  tablet: 2,
  desktop: 3,
  wide: 4,
  wideLarge: 5,
};
export type BreakpointOrderKeys =
  | 'mobile'
  | 'tablet'
  | 'desktop'
  | 'wide'
  | 'wideLarge';

const constrainWidthAtCurrentBreakpoint = (
  currentBreakpoint: BreakpointOrderKeys,
  fromBreakpoint: BreakpointOrderKeys,
  untilBreakpoint: BreakpointOrderKeys,
) =>
  BreakpointOrder[currentBreakpoint] >= BreakpointOrder[fromBreakpoint] &&
  BreakpointOrder[currentBreakpoint] <= BreakpointOrder[untilBreakpoint];

const getMaxPageWidth = (
  breakpointName: BreakpointOrderKeys,
  fromBreakpoint: BreakpointOrderKeys,
  untilBreakpoint: BreakpointOrderKeys,
  useLegacyMQ: boolean,
) => {
  if (useLegacyMQ && breakpointName === 'wideLarge') {
    // The legacy MQs had no wideLarge Breakpoint
    return '';
  }

  return constrainWidthAtCurrentBreakpoint(
    breakpointName,
    fromBreakpoint,
    untilBreakpoint,
  )
    ? `max-width: ${
        useLegacyMQ
          ? maxPageWidthNumbers[breakpointName]
          : maxPageWidthNumbersNew[breakpointName]
      }px`
    : '';
};

const standardItemWidthPlusPaddingEitherSide = (useLegacyMQ: boolean) => {
  const padding = useLegacyMQ
    ? grid.legacyMobileGridPadding
    : grid.mobileGridPadding;
  return grid.standardItemWidth + 2 * padding;
};

const todayMqSmallTablet = css`
  @media (min-width: ${mqToday.tabletSmall}) {
    padding: ${`0 ${grid.gridPadding}px`};
    max-width: ${grid.standardItemWidthMobile * 2 +
    grid.standardGridGap +
    grid.gridPadding * 2}px;
  }
`;

const pageSectionContainer = (
  useLegacyMQ = false,
  hasMobilePadding = true,
  fromBreakpoint: BreakpointOrderKeys = 'mobile',
  untilBreakpoint: BreakpointOrderKeys = 'wideLarge',
  hasTodayMqs: boolean = false,
) => css`
  max-width: 100%;
  ${hasMobilePadding
    ? `padding: 0 ${
        useLegacyMQ ? grid.legacyMobileGridPadding : grid.mobileGridPadding
      }px`
    : ''};
  margin: 0 auto;

  @media (min-width: ${standardItemWidthPlusPaddingEitherSide(useLegacyMQ)}px) {
    ${hasMobilePadding && fromBreakpoint === 'mobile'
      ? `padding: 0 ${useLegacyMQ ? '0' : grid.mobileGridPadding}px`
      : ''};
    ${getMaxPageWidth('mobile', fromBreakpoint, untilBreakpoint, useLegacyMQ)};
  }

  ${hasTodayMqs && todayMqSmallTablet}

  @media (min-width: ${useLegacyMQ ? mq.tablet : mqNew.tablet}) {
    padding: ${`0 ${useLegacyMQ ? '0' : grid.gridPadding}px`};
    ${getMaxPageWidth('tablet', fromBreakpoint, untilBreakpoint, useLegacyMQ)};
  }

  @media (min-width: ${useLegacyMQ ? mq.desktop : mqNew.desktop}) {
    ${getMaxPageWidth('desktop', fromBreakpoint, untilBreakpoint, useLegacyMQ)};
  }

  @media (min-width: ${useLegacyMQ ? mq.wide : mqNew.wide}) {
    ${getMaxPageWidth('wide', fromBreakpoint, untilBreakpoint, useLegacyMQ)};
  }

  @media (min-width: ${mqNew.wideLarge}) {
    ${useLegacyMQ
      ? ''
      : getMaxPageWidth(
          'wideLarge',
          fromBreakpoint,
          untilBreakpoint,
          useLegacyMQ,
        )};
  }
`;

export const styledTransientOptions = {
  shouldForwardProp: (propName: string) => !propName.startsWith('$'),
};

const theme = {
  font,
  color,
  maxPageWidth,
  maxPageWidthNumbers,
  maxPageWidthNew,
  maxPageWidthNumbersNew,
  mq,
  mqNew,
  mqNewNumbers,
  mqToday,
  mqNumbers,
  responsiveImageBreakpoints,
  helpers,
  animations,
  grid,
  pageSectionContainer,
};

export default theme;
