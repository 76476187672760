import { addDays, Locale } from 'date-fns';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import format from 'date-fns/format';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import { de, es, fr, it, nl, pt, tr } from 'date-fns/locale';

import { Translate } from '@app/api/utility-types';

const locales = {
  de,
  es,
  fr,
  it,
  nl,
  pt,
  tr,
};

const getLocaleOptions = (locale: string): Locale =>
  locales[locale] ? locales[locale] : null;

export const formatDate = (
  date: string | Date,
  formatStr: string,
  locale: string,
) => {
  let dateToFormat = null;
  if (typeof date === 'number') {
    dateToFormat = new Date(date * 1000);
  }
  if (typeof date === 'string') {
    dateToFormat = Date.parse(date);
  }
  if (date instanceof Date) {
    dateToFormat = date;
  }
  if (dateToFormat) {
    try {
      const formattedDate = format(dateToFormat, formatStr, {
        locale: getLocaleOptions(locale.substring(0, 2)),
      });
      return formattedDate;
    } catch (error) {
      // Do nothing
    }
  }
  return '';
};

export const formatDateIntl = (
  date: string | Date,
  locale: string,
  options?: Intl.DateTimeFormatOptions,
) => {
  const dateToFormat = typeof date === 'string' ? new Date(date) : date;

  if (dateToFormat) {
    try {
      const formattedDate = new Intl.DateTimeFormat(locale, options).format(
        dateToFormat,
      );
      return formattedDate;
    } catch (error) {
      // Do nothing
    }
  }
  return '';
};

export const formatDateInFutureIntl = (
  startDate: string | Date,
  days: number,
  locale: string,
  options?: Intl.DateTimeFormatOptions,
) => formatDateIntl(addDays(new Date(startDate), days), locale, options);

export const formatDistanceDate = (
  t: Translate,
  date: Date,
  locale: string,
  options: { addSuffix?: boolean; allowJustNow?: boolean } = {
    addSuffix: true,
    allowJustNow: false,
  },
) => {
  const { addSuffix = true, allowJustNow = false } = options;

  if (date) {
    const lessThanOneMinuteAgo = differenceInMinutes(date, new Date()) === 0;
    if (allowJustNow && lessThanOneMinuteAgo) {
      return t('common:common.time.just_now');
    }
    // use "strict" version to keep it more terse (removes 'about', 'less than' etc)
    return formatDistanceToNowStrict(date, {
      addSuffix,
      locale: getLocaleOptions(locale),
    });
  }
  return '';
};
