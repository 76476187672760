import { useEffect, useState } from 'react';
import { shallowEqual } from 'react-redux';
import Head from 'next/head';
import { differenceInSeconds, parseISO } from 'date-fns';

import useAppSelector from '@app/hooks/utils/useAppSelector';

type StandardHeadTagsProps = {
  title?: string;
  noIndex?: boolean;
  showSmartAppBanner?: boolean;
};

const StandardHeadTags = ({
  title = 'MUBI',
  noIndex = false,
  showSmartAppBanner = true,
}: StandardHeadTagsProps) => {
  const { activeSubscriber, user } = useAppSelector(
    state => ({
      activeSubscriber: state.user?.activeSubscriber,
      user: state.user?.user,
    }),
    shallowEqual,
  );

  const [isDarkTheme, setIsDarkTheme] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    setIsDarkTheme(mediaQuery.matches);

    const onThemeChange = event => {
      setIsDarkTheme(event.matches);
    };

    if (mediaQuery.addEventListener) {
      // Safari <=13 do not support `addEventListener`
      mediaQuery.addEventListener('change', onThemeChange);
    }

    return () => {
      if (mediaQuery.removeEventListener) {
        mediaQuery.removeEventListener('change', onThemeChange);
      }
    };
  }, []);

  let secondsUntilSubscriptionExpires: number;

  if (user && activeSubscriber) {
    secondsUntilSubscriptionExpires = differenceInSeconds(
      parseISO(user.subscription.expires_at),
      new Date(),
    );
  }

  return (
    <Head>
      <title>{title}</title>
      {isDarkTheme ? (
        <link
          href="/static/images/icon.png"
          rel="icon"
          type="image/png"
          sizes="32x32"
        />
      ) : (
        <link href="/favicon.ico" rel="icon" type="image/x-icon" />
      )}
      {showSmartAppBanner && (
        <meta content="app-id=626148774" name="apple-itunes-app" />
      )}
      <link href="/apple-touch-icon.png?ver=2" rel="apple-touch-icon" />
      {noIndex && <meta name="robots" content="noindex" />}
      {activeSubscriber && (
        <meta
          name="apple-media-service-subscription"
          content={`expires=${secondsUntilSubscriptionExpires}, type=subscription`}
        />
      )}
    </Head>
  );
};

export default StandardHeadTags;
