import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/nextjs';

import { setBrazeBanner } from '@app/actions/BannerActions';

import useAppSelector from '@app/hooks/utils/useAppSelector';

import { BrazeBannerDetail } from '@app/components/banners/BrazeBanner';

const isProd = process.env.MUBI_ENV === 'production';

// Braze uses a service worker and push notifications to facilitate previewing of content cards.
const registerServiceWorkerToHandleReceiptOfContentCardPreviews = () => {
  navigator.serviceWorker
    // brazeServiceWorker.js is a custom service worker in our codebase.
    .register('https://mubi.com/brazeServiceWorker.js')
    .then(registration =>
      console.log(
        'Service Worker registration successful with scope: ',
        registration.scope,
      ),
    )
    .catch(err => console.log('Service Worker registration failed: ', err));
};

const BrazeInit = () => {
  const [hasInitialisedBraze, setHasInitialisedBraze] = useState(false);

  const brazeUserId = useAppSelector(state => state.user.user?.braze_user_id);
  const userId = useAppSelector(state => state.user?.user?.id);
  const isAdmin = useAppSelector(state => state.user?.user?.is_admin);
  const isAuthenticated = useAppSelector(state => state.user?.isAuthenticated);

  const userCanSeeContentCardPreviews = isAdmin && isProd;

  const dispatch = useDispatch();

  const handleReceiptOfBrazeContentCardPreviews = (
    event: MessageEvent<any>,
  ) => {
    if (isAdmin) {
      console.log('Data received from push notification: ', event.data);
      // Oddly, when Braze sends a content card preview,
      // it puts the extra properties for the content card here: `event.data.ab_cd.e`
      if (event?.data?.ab_cd?.e) {
        dispatch(setBrazeBanner(event?.data?.ab_cd?.e, true));
      }
    }
  };

  useEffect(() => {
    let channelToReceiveBrazePushNotifications: BroadcastChannel;

    try {
      if (isAuthenticated && brazeUserId && !hasInitialisedBraze) {
        if (userCanSeeContentCardPreviews) {
          // 'braze-messages' aligns with channel in our custom brazeServiceWorker.js
          channelToReceiveBrazePushNotifications = new BroadcastChannel(
            'braze-messages',
          );
          registerServiceWorkerToHandleReceiptOfContentCardPreviews();
        }

        import(
          /* webpackExports: ["getCachedContentCards", "initialize", "openSession", "changeUser", "requestContentCardsRefresh", "requestPushPermission"] */
          '@braze/web-sdk'
        ).then(
          async ({
            initialize,
            openSession,
            changeUser,
            getCachedContentCards,
            requestContentCardsRefresh,
            requestPushPermission,
          }) => {
            let initializeConfig = null;

            if (userCanSeeContentCardPreviews) {
              initializeConfig = {
                baseUrl: process.env.BRAZE_BASE_URL,
                enableLogging: true,
                allowUserSuppliedJavascript: true,
                manageServiceWorkerExternally: true,
                serviceWorkerLocation: '/brazeServiceWorker.js',
              };
            } else {
              initializeConfig = {
                baseUrl: process.env.BRAZE_BASE_URL,
                enableLogging: true,
                // enableLogging: false,
                allowUserSuppliedJavascript: true,
              };
            }

            initialize(process.env.BRAZE_API_KEY, initializeConfig);
            if (userCanSeeContentCardPreviews) {
              channelToReceiveBrazePushNotifications.addEventListener(
                'message',
                handleReceiptOfBrazeContentCardPreviews,
              );

              requestPushPermission();
            }

            changeUser(String(brazeUserId));

            openSession();

            setHasInitialisedBraze(true);

            try {
              requestContentCardsRefresh(() => {
                const feed = getCachedContentCards();
                if (Array.isArray(feed?.cards) && feed.cards.length > 0) {
                  if (feed.cards[0].extras) {
                    dispatch(
                      setBrazeBanner(
                        feed.cards[0].extras as BrazeBannerDetail,
                        false,
                        feed.cards[0],
                      ),
                    );
                  }
                }

                // if (userCanSeeContentCardPreviews) {
                console.log('Braze content card feed', feed);
                // }
              });
            } catch (error) {
              // if (userCanSeeContentCardPreviews) {
              console.log('Braze getCachedFeed error.', error);
              // }
            }
          },
        );
      }
    } catch (error) {
      Sentry.captureException(error);
    }

    return () => {
      if (
        userCanSeeContentCardPreviews &&
        channelToReceiveBrazePushNotifications
      ) {
        channelToReceiveBrazePushNotifications.removeEventListener(
          'message',
          handleReceiptOfBrazeContentCardPreviews,
        );
      }
    };
  }, [brazeUserId, userId]);

  return null;
};

export default BrazeInit;
