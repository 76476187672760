import HttpService from '@app/api/http-service';
import { FilmId } from '@app/api/resources/Film';

import { ObjectOfStrings } from '@app/types/utility-types';
import { color as themeColors } from '@app/themes/mubi-theme';

export const archetypes: ArchetypeKey[] = [
  'activist',
  'aeronaut',
  'aesthete',
  'animal_whisperer',
  'animaniac',
  'anthropologist',
  'audiophile',
  'awards_buff',
  'coming_of_ager',
  'cosmic_explorer',
  'credits_watcher',
  'critics_friend',
  'culture_vulture',
  'doomsayer',
  'double_agent',
  'fashionista',
  'feminist',
  'festival_head',
  'foodie',
  'fossilhunter',
  'futurist',
  'genre_junkie',
  'globe_trotter',
  'go_pro',
  'historian',
  'horrorphile',
  'illuminator',
  'joker',
  'legal_eagle',
  'logophobe',
  'marathoner',
  'martial_artist',
  'medic',
  'minimalist',
  'modernist',
  'mythologist',
  'nailbiter',
  'newshound',
  'noir_connoisseur',
  'nostalgic',
  'optimist',
  'philosopher',
  'polyglot',
  'prime_suspect',
  'rater',
  'rebel',
  'reviewer',
  'romantic',
  'slow_burner',
  'speed_freak',
  'spiritualist',
  'surrealist',
  'teamplayer',
  'tear_jerker',
  'techie',
  'thrillseeker',
  'time_traveller',
  'truthseeker',
  'twist_chaser',
  'wanderer',
  'wise_guy',
];

export type ArchetypeKey =
  | 'activist'
  | 'aeronaut'
  | 'aesthete'
  | 'animal_whisperer'
  | 'animaniac'
  | 'anthropologist'
  | 'audiophile'
  | 'awards_buff'
  | 'coming_of_ager'
  | 'cosmic_explorer'
  | 'credits_watcher'
  | 'critics_friend'
  | 'culture_vulture'
  | 'doomsayer'
  | 'double_agent'
  | 'fashionista'
  | 'feminist'
  | 'festival_head'
  | 'foodie'
  | 'fossilhunter'
  | 'futurist'
  | 'genre_junkie'
  | 'globe_trotter'
  | 'go_pro'
  | 'historian'
  | 'horrorphile'
  | 'illuminator'
  | 'joker'
  | 'legal_eagle'
  | 'logophobe'
  | 'marathoner'
  | 'martial_artist'
  | 'medic'
  | 'minimalist'
  | 'modernist'
  | 'mythologist'
  | 'nailbiter'
  | 'newshound'
  | 'noir_connoisseur'
  | 'nostalgic'
  | 'optimist'
  | 'philosopher'
  | 'polyglot'
  | 'prime_suspect'
  | 'rater'
  | 'rebel'
  | 'reviewer'
  | 'romantic'
  | 'slow_burner'
  | 'speed_freak'
  | 'spiritualist'
  | 'surrealist'
  | 'teamplayer'
  | 'tear_jerker'
  | 'techie'
  | 'thrillseeker'
  | 'time_traveller'
  | 'truthseeker'
  | 'twist_chaser'
  | 'wanderer'
  | 'wise_guy';

export type ArchetypeGraphicProps = { idPrefix: string; playOnce?: boolean };

export const generateRandomArrayOfUpToFourArchetypes = (
  numberOfArchetypes?: number,
): ArchetypeKey[] => {
  const shuffledArchetypes = [...archetypes].sort(() => Math.random() - 0.5);
  const numberOfArchetypesToReturn =
    numberOfArchetypes ||
    Math.floor(Math.random() * Math.min(4, archetypes.length)) + 1;
  return shuffledArchetypes.slice(0, numberOfArchetypesToReturn);
};

export const snakeToPascal = (str: string) =>
  str
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join('');

export type RetrospectiveVariant = 'personal' | 'generic' | 'impersonal';
export type RetrospectiveFilmRecOption = 'same' | 'different';
export type RetrospectiveBackgroundColor =
  | 'purple'
  | 'electricMint'
  | 'yellow'
  | 'blue';

export type RetroReferralPage = {
  hidePage: boolean;
  textKey: string;
  subtextKey: string;
  ctaAndAction: {
    ctaKey: string;
    clickAction: () => void;
    snowplowClickType: string;
  };
  showAppCopyLink: boolean;
};

export const FIRST_ARCHETYPE_DELAY = 1000;
export const ARCHETYPE_DISPLAY_INTERVAL = 5000;

export const ARCHETYPE_FADE_MS = 300;

export const retroAspectRatioMQ = props =>
  `(min-aspect-ratio: 4/7) and (max-width: ${props.theme.mqNew.tablet})`;

export const getArchetypeKeys = (retrospective: Retrospective) =>
  retrospective.archetypes.map(archetype => archetype.key);

export const getRetrospective = (
  httpContext: ObjectOfStrings,
  tokenOrCountryCode: string,
): Promise<{ data: Retrospective }> =>
  HttpService(httpContext).get(`/retrospectives/${tokenOrCountryCode}`);

export type Archetype = { id: number; key: ArchetypeKey; film_ids: FilmId[] };

export type Retrospective = {
  archetypes: Archetype[];
  anti_archetypes: Archetype[];
  assets: {
    type: 'image' | 'video';
    orientation: 'horizontal' | 'vertical';
    url: string;
  }[];
  display_name?: string;
};

export const getRetrospectivePageStructure = (
  retrospective: Retrospective,
  hideReferralPage: boolean,
): {
  pageDuration: number;
  label: string;
  pageIndex: number;
  flipNavAndLogoColour?: boolean;
  backgroundColor: RetrospectiveBackgroundColor;
  activeArchetype: ArchetypeKey;
}[] => {
  const numberOfArchetypesWithFilmRecs = retrospective.archetypes.filter(
    archetype => archetype?.film_ids?.length > 0,
  ).length;
  const numberOfAntiArchetypesWithFilmRecs =
    retrospective.anti_archetypes?.filter(
      archetype => archetype?.film_ids?.length > 0,
    ).length;

  const sameRecsPageLabels =
    numberOfArchetypesWithFilmRecs > 0
      ? [
          '2_same_recs_intro',
          ...Array.from(
            { length: numberOfArchetypesWithFilmRecs },
            (_, i) => `3_same_recs_${i}`,
          ),
        ]
      : [];

  let differentRecsPageLabels = [];

  // If there are anti-archetype film recs but no archetype film recs, we still need
  // to show the archetypes intro page as well as the anti-archetypes intro page
  // (but not the other way)
  if (numberOfAntiArchetypesWithFilmRecs > 0) {
    if (numberOfArchetypesWithFilmRecs > 0) {
      differentRecsPageLabels = ['4_different_recs_intro', '5_different_recs'];
    } else {
      differentRecsPageLabels = [
        '2_same_recs_intro',
        '4_different_recs_intro',
        '5_different_recs',
      ];
    }
  }

  const pageLabels = [
    '1_archetype_summary',
    ...sameRecsPageLabels,
    ...differentRecsPageLabels,
    ...(hideReferralPage ? [] : ['6_referral']),
    '7_final_summary',
  ];

  const getDurationForPageLabel = (label: string) => {
    if (label === '1_archetype_summary') {
      return (
        retrospective.archetypes.length *
          (ARCHETYPE_DISPLAY_INTERVAL + ARCHETYPE_FADE_MS) +
        8000
      );
    } else if (
      label === '2_same_recs_intro' ||
      label === '4_different_recs_intro'
    ) {
      return 4000;
    } else if (label === '6_referral') {
      return 8000;
    } else {
      return 10000;
    }
  };

  const getBackgroundColorForPageLabel = (
    label: string,
  ): RetrospectiveBackgroundColor => {
    if (label === '2_same_recs_intro') {
      return 'yellow';
    } else if (label === '4_different_recs_intro') {
      return 'electricMint';
    } else if (label === '6_referral') {
      return 'blue';
    }
    return 'purple';
  };

  const getActiveArchetype = (label: string) => {
    if (label.startsWith('3_same_recs_')) {
      return retrospective.archetypes[label.slice(-1)].key;
    } else if (label === '5_different_recs') {
      return retrospective.anti_archetypes[0].key;
    }
    return null;
  };

  return pageLabels.map((label, index) => ({
    label,
    pageIndex: index,
    ...(label === '2_same_recs_intro' || label === '4_different_recs_intro'
      ? { flipNavAndLogoColour: true }
      : {}),
    pageDuration: getDurationForPageLabel(label),
    backgroundColor: getBackgroundColorForPageLabel(label),
    activeArchetype: getActiveArchetype(label),
  }));
};

export const getRetrospectiveShareUrl = (fullUrl: string) =>
  fullUrl.split('?')[0];

export type NativePlatform = 'iOS' | 'android';

export const getShowNativeOptions = (platform: NativePlatform) =>
  platform === 'iOS' ||
  platform === 'android' ||
  ('canShare' in navigator && navigator.canShare({ url: 'testurl' }));

type NativeAction =
  | 'filmLink'
  | 'referral'
  | 'shareWithInstagram'
  | 'shareWithTikTok'
  | 'shareUrl';

export const sendEventToIOS = (action: NativeAction, data: ObjectOfStrings) =>
  (window as any)?.webkit?.messageHandlers?.[action].postMessage(data);

export const sendEventToAndroid = (
  action: NativeAction,
  data: ObjectOfStrings,
) => {
  (window as any)?.AndroidBridge?.[action](JSON.stringify(data));
};

const darkBackgroundArrowColors = {
  left: {
    backgroundColor: themeColors.retrospective.purple,
    borderColor: themeColors.white,
    arrowColor: themeColors.white,
    hoverBackgroundColor: themeColors.retrospective.purple,
    hoverBorderColor: themeColors.retrospective.electricMint,
    hoverArrowColor: themeColors.retrospective.electricMint,
  },
  right: {
    backgroundColor: themeColors.white,
    borderColor: themeColors.white,
    arrowColor: themeColors.retrospective.purple,
    hoverBackgroundColor: themeColors.retrospective.electricMint,
    hoverBorderColor: themeColors.retrospective.electricMint,
    hoverArrowColor: themeColors.retrospective.purple,
  },
};

const lightBackgroundArrowColors = {
  left: {
    backgroundColor: themeColors.retrospective.yellow,
    borderColor: themeColors.retrospective.purple,
    arrowColor: themeColors.retrospective.purple,
    hoverBackgroundColor: themeColors.retrospective.purple,
    hoverBorderColor: themeColors.retrospective.electricMint,
    hoverArrowColor: themeColors.retrospective.electricMint,
  },
  right: {
    backgroundColor: themeColors.retrospective.purple,
    borderColor: themeColors.retrospective.purple,
    arrowColor: themeColors.white,
    hoverBackgroundColor: themeColors.retrospective.electricMint,
    hoverBorderColor: themeColors.retrospective.electricMint,
    hoverArrowColor: themeColors.retrospective.purple,
  },
};

export const arrowColorsByDirectionAndBackground = {
  left: {
    purple: darkBackgroundArrowColors.left,
    blue: {
      ...darkBackgroundArrowColors.left,
      backgroundColor: themeColors.retrospective.blue,
      hoverBackgroundColor: themeColors.retrospective.blue,
    },
    yellow: lightBackgroundArrowColors.left,
    electricMint: {
      ...lightBackgroundArrowColors.left,
      backgroundColor: themeColors.retrospective.electricMint,
      hoverBackgroundColor: themeColors.retrospective.electricMint,
      hoverBorderColor: themeColors.white,
      hoverArrowColor: themeColors.white,
    },
  },
  right: {
    purple: darkBackgroundArrowColors.right,
    blue: darkBackgroundArrowColors.right,
    yellow: lightBackgroundArrowColors.right,
    electricMint: {
      ...lightBackgroundArrowColors.right,
      hoverBackgroundColor: themeColors.retrospective.purple,
      hoverBorderColor: themeColors.retrospective.purple,
      hoverArrowColor: themeColors.retrospective.electricMint,
    },
  },
};
