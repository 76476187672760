// App state actions
export const SET_SHOW_FIND_USERS_MODAL = 'SET_SHOW_FIND_USERS_MODAL';
export const SET_SHOW_ONBOARDING_MODAL = 'SET_SHOW_ONBOARDING_MODAL';
export const SET_ONBOARDING_CONTEXT = 'SET_ONBOARDING_CONTEXT';
export const SET_ONBOARDING_CONFIG = 'SET_ONBOARDING_CONFIG';
export const SET_SHOW_MANAGE_FILM_LIST_MODAL =
  'SET_SHOW_MANAGE_FILM_LIST_MODAL';
export const SET_SHOW_RATING_MODAL = 'SET_SHOW_RATING_MODAL';
export const SET_DETECTED_BROWSER = 'SET_DETECTED_BROWSER';
export const SET_USER_COUNT = 'SET_USER_COUNT';
export const SET_HTTP_CONTEXT = 'SET_HTTP_CONTEXT';
export const ADD_PARAM_TO_HTTP_CONTEXT = 'ADD_PARAM_TO_HTTP_CONTEXT';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_HIDE_TOP_NAV = 'SET_HIDE_TOP_NAV';
export const SET_USER_HAS_INTERACTED_WITH_PAGE =
  'SET_USER_HAS_INTERACTED_WITH_PAGE';
export const SET_SNOWPLOW_INITIALISED = 'SET_SNOWPLOW_INITIALISED';
export const SET_PROMO_BANNER_COOKIES = 'SET_PROMO_BANNER_COOKIES';
export const SET_PROMO_BANNER_PROMO = 'SET_PROMO_BANNER_PROMO';
export const SET_PROMO_PROMOTED = 'SET_PROMO_PROMOTED';
export const SET_VIEWING_TRACKING_INTERVAL = 'SET_VIEWING_TRACKING_INTERVAL';
export const SET_SPLASH_VIDEO = 'SET_SPLASH_VIDEO';
export const SET_SPLASH_STILL = 'SET_SPLASH_STILL';
export const SET_FILTER_BROWSE_FILMS_BY_NOW_SHOWING =
  'SET_FILTER_BROWSE_FILMS_BY_NOW_SHOWING';
export const SET_CONTENT_WARNING_PREFERENCE_ENABLED =
  'SET_CONTENT_WARNING_PREFERENCE_ENABLED';
export const DISABLE_TRIALS = 'DISABLE_TRIALS';
export const SHOW_FLAGS = 'SHOW_FLAGS';
export const SET_GOOGLE_TAGS_INIT = 'SET_GOOGLE_TAGS_INIT';
export const SET_VANITY_PATH_PAGE_TYPE = 'SET_VANITY_PATH_PAGE_TYPE';
export const SET_MUBI_GO_ENABLED = 'SET_MUBI_GO_ENABLED';
export const SET_CANCELLATION_STEP = 'SET_CANCELLATION_STEP';

// User actions
export const SET_USER_INITIALISED_ON_PAGE_LOAD =
  'SET_USER_INITIALISED_ON_PAGE_LOAD';
export const LOG_USER_IN = 'LOG_USER_IN';
export const LOG_USER_OUT = 'LOG_USER_OUT';
export const UPDATE_LOGGED_IN_USER = 'UPDATE_LOGGED_IN_USER';
export const SET_USER_GEO_LOCATION = 'SET_USER_GEO_LOCATION';
export const SET_CURRENT_LANGUAGE = 'SET_CURRENT_LANGUAGE';
export const SET_WATCH_LIST = 'SET_WATCH_LIST';
export const ADD_TO_WATCH_LIST = 'ADD_TO_WATCH_LIST';
export const REMOVE_FROM_WATCH_LIST = 'REMOVE_FROM_WATCH_LIST';
export const SET_ACCEPTED_LANGUAGES = 'SET_ACCEPTED_LANGUAGES';
export const SET_SUBSCRIPTION_PLANS = 'SET_SUBSCRIPTION_PLANS';
export const SET_SUBSCRIPTION_PLANS_FOR_USER =
  'SET_SUBSCRIPTION_PLANS_FOR_USER';
export const ADD_ITEMS_TO_WATCH_LIST = 'ADD_ITEMS_TO_WATCH_LIST';
export const SET_FILTER_BY_LOCAL_FILMS = 'SET_FILTER_BY_LOCAL_FILMS';
export const SET_USER_RATINGS = 'SET_USER_RATINGS';
export const REMOVE_USER_RATING_FOR_FILM = 'REMOVE_USER_RATING_FOR_FILM';
export const SET_USE_US_ENGLISH = 'SET_USE_US_ENGLISH';
export const SET_COUNTRY_FULL_TIME_ZONE = 'SET_COUNTRY_FULL_TIME_ZONE';
export const SET_DISMISSED_BANNERS = 'SET_DISMISSED_BANNERS';
export const DISMISS_A_BANNER = 'DISMISS_A_BANNER';
export const SET_PAYMENT_METHOD_FOR_USER = 'SET_PAYMENT_METHOD_FOR_USER';
export const SET_LOCAL_ILLOW_COOKIE_CONSENT = 'SET_LOCAL_ILLOW_COOKIE_CONSENT';
export const SET_PARENTAL_CONTROL_FOR_USER = 'SET_PARENTAL_CONTROL_FOR_USER';
export const SET_SUBSCRIPTION_CANCELLATION = 'SET_SUBSCRIPTION_CANCELLATION';

// List actions
export const ADD_LIST = 'ADD_LIST';
export const ADD_PAGE_OF_LIST_FILMS = 'ADD_PAGE_OF_LIST_FILMS';
export const DELETE_PAGES_OF_LIST_FILMS = 'DELETE_PAGES_OF_LIST_FILMS';
export const ADD_PAGE_OF_LIST_FOLLOWERS = 'ADD_PAGE_OF_LIST_FOLLOWERS';
export const SET_USERS_LISTS = 'SET_USERS_LISTS';
export const UPDATE_USERS_LIST = 'UPDATE_USERS_LIST';
export const ADD_USERS_LIST = 'ADD_USERS_LIST';
export const DELETE_USERS_LIST = 'DELETE_USERS_LIST';
export const ADD_ITEM_TO_USERS_LIST = 'ADD_ITEM_TO_USERS_LIST';
export const DELETE_ITEM_FROM_USERS_LISTS = 'DELETE_ITEM_FROM_USERS_LISTS';

// Favorite actions
export const ADD_FAVORITES_OF_TYPE = 'ADD_FAVORITES_OF_TYPE';
export const FAVORITE_ITEM_OF_TYPE = 'FAVORITE_ITEM_OF_TYPE';
export const UNFAVORITE_ITEM_OF_TYPE = 'UNFAVORITE_ITEM_OF_TYPE';

// Following actions
export const ADD_FOLLOWING = 'ADD_FOLLOWING';
export const REMOVE_FOLLOWING = 'REMOVE_FOLLOWING';
export const ADD_FOLLOWINGS = 'ADD_FOLLOWINGS';
export const ADD_USERS_NOT_FOLLOWED = 'ADD_USERS_NOT_FOLLOWED';

// Film actions
export const SET_FILM = 'SET_FILM';
export const SET_FILMS = 'SET_FILMS';

// Consumables actions
export const ADD_CONSUMABLES_BY_FILMS = 'ADD_CONSUMABLES_BY_FILMS';
export const SET_CONSUMABLE_FOR_FILM = 'SET_CONSUMABLE_FOR_FILM';

// Film group actions
export const ADD_FILM_GROUPS = 'ADD_FILM_GROUPS';
export const ADD_FILM_GROUP = 'ADD_FILM_GROUP';
export const CLEAR_ALL_FILM_GROUPS = 'CLEAR_ALL_FILM_GROUPS';

// Film group highlight actions
export const SET_FILM_GROUP_HIGHLIGHTS = 'SET_FILM_GROUP_HIGHLIGHTS';
export const ADD_FILM_GROUP_HIGHLIGHT = 'ADD_FILM_GROUP_HIGHLIGHT';
export const CLEAR_ALL_FILM_GROUP_HIGHLIGHTS =
  'CLEAR_ALL_FILM_GROUP_HIGHLIGHTS';

export const SET_USERS_FILM_VIEWINGS = 'SET_USERS_FILM_VIEWINGS';
export const SET_USER_FILM_VIEWING = 'SET_USER_FILM_VIEWING';

// Spotlights actions
export const SET_SPOTLIGHTS = 'SET_SPOTLIGHTS';
export const CLEAR_ALL_SPOTLIGHTS = 'CLEAR_ALL_SPOTLIGHTS';

// PlaybackLanguages actions
export const SET_PLAYBACK_LANGUAGE_BY_FILM_ID =
  'SET_PLAYBACK_LANGUAGE_BY_FILM_ID';

// Notebook post actions
export const SET_NOTEBOOK_POSTS = 'SET_NOTEBOOK_POSTS';
export const ADD_NOTEBOOK_POSTS = 'ADD_NOTEBOOK_POSTS';
export const ADD_PAGE_OF_NOTEBOOK_POSTS_FOR_HOME =
  'ADD_PAGE_OF_NOTEBOOK_POSTS_FOR_HOME';
export const ADD_PAGE_OF_NOTEBOOK_POSTS_FOR_ALL_POSTS =
  'ADD_PAGE_OF_NOTEBOOK_POSTS_FOR_ALL_POSTS';
export const ADD_PAGE_OF_NOTEBOOK_POSTS_FOR_TAG =
  'ADD_PAGE_OF_NOTEBOOK_POSTS_FOR_TAG';
export const ADD_PAGE_OF_NOTEBOOK_POSTS_FOR_AUTHOR =
  'ADD_PAGE_OF_NOTEBOOK_POSTS_FOR_AUTHOR';

// Notebook cover actions
export const SET_NOTEBOOK_COVER = 'SET_NOTEBOOK_COVER';

// Notebook tag actions
export const SET_NOTEBOOK_TAGS = 'SET_NOTEBOOK_TAGS';

// Notebook magazine
export const SET_MAGAZINE_SUBSCRIPTION = 'SET_MAGAZINE_SUBSCRIPTION';

// Dealer actions
export const SET_DEALER_CUSTOMER_DETAILS = 'SET_DEALER_CUSTOMER_DETAILS';
export const CLEAR_DEALER_CUSTOMER_DETAILS = 'CLEAR_DEALER_CUSTOMER_DETAILS';

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';

// Critic reviews actions
export const SET_CRITIC_REVIEWS_HIGHLIGHTS_FOR_FILM =
  'SET_CRITIC_REVIEWS_HIGHLIGHTS_FOR_FILM';

// Cast members actions
export const ADD_CAST_MEMBER = 'ADD_CAST_MEMBER';
export const ADD_CAST_MEMBERS_FILMS = 'ADD_CAST_MEMBERS_FILMS';
export const CLEAR_ALL_CAST_MEMBERS_FILMS_FOR_CREDIT =
  'CLEAR_ALL_CAST_MEMBERS_FILMS_FOR_CREDIT';
export const ADD_CAST_MEMBERS_NOW_SHOWING_FILM_IDS =
  'ADD_CAST_MEMBERS_NOW_SHOWING_FILM_IDS';

// Film rating actions
export const ADD_RATINGS_PAGE_FOR_USER = 'ADD_RATINGS_PAGE_FOR_USER';

// Comments
export const SET_COMMENTS = 'SET_COMMENTS';
export const ADD_COMMENT = 'ADD_COMMENT';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';

// Film highlights
export const SET_FILM_HIGHLIGHTS = 'SET_FILM_HIGHLIGHTS';

// Film group spotlights
export const SET_FILM_GROUP_SPOTLIGHTS = 'SET_FILM_GROUP_SPOTLIGHTS';

// Today sections
export const SET_TODAY_LAYOUT_ITEMS = 'SET_TODAY_LAYOUT_ITEMS';
export const ADD_TODAY_SECTION = 'ADD_TODAY_SECTION';
export const REMOVE_TODAY_SECTION = 'REMOVE_TODAY_SECTION';

// Film tile actions
export const SET_PREVIEW_CLIP_PLAYER = 'SET_PREVIEW_CLIP_PLAYER';
export const CLEAR_PREVIEW_CLIP_PLAYER = 'CLEAR_PREVIEW_CLIP_PLAYER';
export const SET_PREVIEW_CLIP_PLAYER_STATUS = 'SET_PREVIEW_CLIP_PLAYER_STATUS';
export const SET_PREVIEW_CLIP_PLAYER_MUTED = 'SET_PREVIEW_CLIP_PLAYER_MUTED';
export const SET_PREVIEW_CLIP_PLAYER_SUBS = 'SET_PREVIEW_CLIP_PLAYER_SUBS';
export const SET_PREVIEW_CLIP_PLAYER_DURATION =
  'SET_PREVIEW_CLIP_PLAYER_DURATION';

// Banner actions
export const SET_BRAZE_BANNER = 'SET_BRAZE_BANNER';
export const CLEAR_BRAZE_BANNER = 'CLEAR_BRAZE_BANNER';
export const SET_USER_FEEDBACK_BANNER = 'SET_USER_FEEDBACK_BANNER';
export const SET_USER_STATE_BANNERS = 'SET_USER_STATE_BANNERS';

// Payment Gateway actions
export const SET_CREDIT_CARD_PAYMENT_GATEWAY =
  'SET_CREDIT_CARD_PAYMENT_GATEWAY';
export const SET_PAYPAL_CONFIG = 'SET_PAYPAL_CONFIG';

// Experiment actions
export const SET_EXPERIMENT = 'SET_EXPERIMENT';
export const RESET_EXPERIMENTS = 'RESET_EXPERIMENTS';

// Retrospective
export const SET_RETROSPECTIVE = 'SET_RETROSPECTIVE';
export const SET_FORCE_IS_PLAYING = 'SET_FORCE_IS_PLAYING';
export const SET_ACTIVE_RETROSPECTIVE_PAGE_INDEX =
  'SET_ACTIVE_RETROSPECTIVE_PAGE_INDEX';
export const SET_RETROSPECTIVE_VARIANT = 'SET_RETROSPECTIVE_VARIANT';
export const SET_RETROSPECTIVE_ACTIVE_ARCHETYPE =
  'SET_RETROSPECTIVE_ACTIVE_ARCHETYPE';
export const SET_REFERRAL_PAGE_CONTENT = 'SET_REFERRAL_PAGE_CONTENT';
export const SET_HANDLE_NEXT_OVERRIDE = 'SET_HANDLE_NEXT_OVERRIDE';
export const SET_HANDLE_PREVIOUS_OVERRIDE = 'SET_HANDLE_PREVIOUS_OVERRIDE';
