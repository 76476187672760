import { shallowEqual } from 'react-redux';
import { useRouter } from 'next/router';

import { Film, getIsExclusive, getIsWatchable } from '@app/api/resources/Film';

import {
  getSnowplowPageType,
  trackSnowplowClickEvent,
} from '@app/services/snowplow';

import { ObjectOfAny } from '@app/types/utility-types';

import useAppSelector from '@app/hooks/utils/useAppSelector';

type DoTrackSnowplowClickEventParams = {
  data: { clickType: string; element?: string };
  extraData?: ObjectOfAny;
  film?: Film;
  callback?: () => any;
};

const useSnowplowClickTracker = () => {
  const router = useRouter();
  const { httpContext, countryCode, vanityPathPageType, retrospectiveState } =
    useAppSelector(
      state => ({
        httpContext: state.appState.httpContext,
        countryCode: state.user.geoLocation,
        vanityPathPageType: state.appState.pageState?.vanityPath?.pageType,
        retrospectiveState: state.retrospective,
      }),
      shallowEqual,
    );

  const getFilmDataIfRelatedFilm = (film: Film) => {
    if (film) {
      if (film.consumable) {
        return {
          film_id: film.id,
          is_watchable: getIsWatchable(film),
          is_exclusive: getIsExclusive(film),
        };
      }
      return {
        film_id: film.id,
        is_watchable: false,
        is_exclusive: false,
      };
    }
    return {};
  };

  const doTrackSnowplowClickEvent = async ({
    data,
    extraData,
    film,
    callback,
  }: DoTrackSnowplowClickEventParams) => {
    try {
      const { clickType, element } = data;
      const pageType = await getSnowplowPageType(
        router,
        vanityPathPageType,
        retrospectiveState,
      );
      const relatedFilmData = getFilmDataIfRelatedFilm(film);

      trackSnowplowClickEvent(
        {
          click_type: clickType,
          element,
          ...pageType,
          ...extraData,
          ...relatedFilmData,
          country_code: countryCode,
          language: router.locale,
        },
        callback,
        httpContext,
      );
    } catch (error) {
      // Ignore when tracking to snowplow fails
    }
  };

  return doTrackSnowplowClickEvent;
};

export default useSnowplowClickTracker;
